import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  ApolloLink,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { csCZ } from "@mui/x-date-pickers/locales";
import "moment/locale/cs";

const link = createUploadLink({
  uri: process.env.REACT_APP_SERVER_BASE_PATH + "/graphql",
  credentials: "include",
  headers: { "Apollo-Require-Preflight": "true" },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    // @ts-ignore
    graphQLErrors.forEach(({ message, locations, path, data }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (data && data.status === 401) {
        window.location.reload();
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  // cache: new InMemoryCache({ addTypename: false }),
  cache: new InMemoryCache(),
  link: from([errorLink, link as unknown as ApolloLink]),
  headers: { "Apollo-Require-Preflight": "true" },
});

const theme = createTheme(
  {
    palette: {
      background: {
        default: "#f8f8fd",
      },
      primary: {
        main: "#CC0000",
      },
      secondary: {
        // main: "#dce2f9",
        main: "#dfdfdf",
      },
      success: {
        main: "#49b04f",
      },
    },
    typography: {
      h1: {
        fontSize: 28,
        fontWeight: "bold",
      },
      h2: {
        fontSize: 22,
        fontWeight: "bold",
        // color: "#4d4d4d",
        marginBottom: 4,
      },
      h3: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: "rgb(112, 112, 112)",
      },
      h6: {
        fontSize: "1em",
        fontWeight: "bold",
      },
      // h7: {
      //   fontWeight: "400",
      //   fontSize: "0.9em",
      //   color: grey[600],
      // },
      subtitle2: {
        color: "#707070",
      },
      body2: {
        // fontSize: "0.85em",
        color: "#707070",
      },
    },
    shape: {
      borderRadius: 10,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // backgroundColor: "#e6e6e6",
            backgroundColor: "#ffffff",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: "bold",
          },
        },
      },
    },
  },
  csCZ
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="cs">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
      {/* <RouterProvider router={router} /> */}
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
